import React, { useRef, useState } from 'react';

import ReCaptcha from '@pittica/gatsby-plugin-recaptcha';
import SimpleReactValidator from 'simple-react-validator';

import { FormattedMessage, useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { Button } from '../../Molecules/Button/Button';
import Input from '../../Molecules/Input/Input';
import Textarea from '../../Molecules/Textarea/Textarea';
import Dropdown from '../Dropdown/Dropdown';

import './ContactForm.scss';
const axios = require('axios').default;

const ContactForm = ({ formName, langCode }) => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [subject, setSubject] = useState('');
  const [lastname, setLastname] = useState('');
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const webFormSessionUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}session/token`;
  const webFormSubmitUrl = `${process.env.GATSBY_DRUPAL_BASE_URL}${langCode}/webform_rest/submit?_format=json`;

  const intl = useIntl();

  const agenciesList = [
    {
      id: 0,
      label: 'Siège social'
    },
    {
      id: 1,
      label: 'Toulouse'
    },
    {
      id: 2,
      label: 'Villette-de-Vienne'
    },
    {
      id: 3,
      label: 'Saint-Germain-de-la-Grange '
    },
    {
      id: 4,
      label: 'Port-de-Bouc '
    },
    {
      id: 5,
      label: 'Bordeaux'
    },
    {
      id: 6,
      label: 'Hennebont '
    },
    {
      id: 7,
      label: 'Gennevilliers'
    },
    {
      id: 8,
      label: 'Ploumagoar '
    },
    {
      id: 9,
      label: 'Montgivray'
    },
    {
      id: 10,
      label: 'Dunkerque'
    },
    {
      id: 11,
      label: 'Nanterre'
    },
    {
      id: 12,
      label: 'Châteaulin'
    },
    {
      id: 13,
      label: 'Joué-Les-Tours'
    },
    {
      id: 14,
      label: 'Beaurains'
    },
    {
      id: 15,
      label: 'Aulnay-sous-Bois'
    },
    {
      id: 16,
      label: 'Abidjan'
    },
    {
      id: 17,
      label: 'Casablanca'
    },
    {
      id: 18,
      label: 'Conakry'
    },
    {
      id: 19,
      label: 'Libreville'
    },
    {
      id: 20,
      label: 'Hanovre'
    }
  ];

  const [selectedAgency, setSelectedAgency] = useState(agenciesList[0].label);

  const locale = {
    'accepted': intl.messages['common.form.error.accepted'],
    'alpha': intl.messages['common.form.error.alpha'],
    'alpha_space': intl.messages['common.form.error.alpha_space'],
    'boolean': intl.messages['common.form.error.boolean'],
    'email': intl.messages['common.form.error.email'],
    'numeric': intl.messages['common.form.error.numeric'],
    'phone': intl.messages['common.form.error.phone'],
    'regex': intl.messages['common.form.error.regex'],
    'required': intl.messages['common.form.error.required'],
    'string': intl.messages['common.form.error.string'],
    'typeof': intl.messages['common.form.error.typeof'],
  };

  SimpleReactValidator.addLocale(langCode, locale);

  const validator = useRef(new SimpleReactValidator({ autoForceUpdate: { forceUpdate: forceUpdate }, locale: langCode }));
  const handleSubmitForm = (token) => {
    //console.log(selectedAgency);
    if (token.length > 0) {
      if (validator.current.allValid()) {
        setIsError(false);
        axios.get(webFormSessionUrl).then(result => {
          if (200 === result.status) {
            const csrfToken = result.data;
            fetch(webFormSubmitUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
              },
              body: JSON.stringify({
                'webform_id': formName,
                'surname': lastname,
                'name': firstname,
                'message': message,
                'email': email,
                'subject': subject,
                'agency': selectedAgency
              }),
            })
              .then(response => response.json())
              .then(
                (data) => {
                  //on success you do whatever you will want
                  //console.log('success', data);
                  setIsEmailSent(true);
                  window.scrollTo({ top: document.querySelector('.section_contact_form').offsetTop - 100, behavior: 'smooth' });
                },
                (error) => {
                  console.log('error', error);
                }
              );
          }
        });
      } else {
        validator.current.showMessages();
        setIsError(true);
        setSubmitted(false);
      }
    } else {
      console.log(token, 'no token ?');
      setSubmitted(false);
      alert('Error : Token of recaptcha is not valid, please try again');
    }
  };

  const dropDownChange = (agency) => {
    setSelectedAgency(agency?.label);
  };

  return (
    <div className="contact_form_container">
      {isError &&
        <FormattedMessage id="common.form.required">{x => <span className="red">* {x}</span>}</FormattedMessage>
      }
      {
        isEmailSent &&
        <div className='email-sent'>
          <h3 aria-live="polite"><FormattedMessage id="common.form.email_sent" /></h3>
        </div>
      }
      {!isEmailSent && ( //onSubmit={handleSubmitForm}
        <form>
          <Input
            validator={validator.current}
            field={{
              id: 'subject',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.subject.placeholder' }),
              name: 'subject',
              label: intl.formatMessage({ id: 'contact.form.field.subject.label' }),
              value: subject,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setSubject('')}
            change={(value) => setSubject(value)}
          />
          <Input
            validator={validator.current}
            field={{
              id: 'lastname',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.lastname.placeholder' }),
              name: 'lastname',
              label: intl.formatMessage({ id: 'contact.form.field.lastname.label' }),
              value: lastname,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setLastname('')}
            change={(value) => setLastname(value)}
          />
          <Input
            validator={validator.current}
            field={{
              id: 'firstname',
              filed_type: 'input',
              type: 'text',
              placeholder: intl.formatMessage({ id: 'contact.form.field.firstname.placeholder' }),
              name: 'firstname',
              label: intl.formatMessage({ id: 'contact.form.field.firstname.label' }),
              value: firstname,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setFirstname('')}
            change={(value) => setFirstname(value)}
          />
          <Input
            validator={validator.current}
            field={{
              id: 'email',
              filed_type: 'input',
              type: 'email',
              placeholder: intl.formatMessage({ id: 'contact.form.field.email.placeholder' }),
              name: 'email',
              label: intl.formatMessage({ id: 'contact.form.field.email.label' }),
              value: email,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => setEmail('')}
            change={(value) => setEmail(value)}
          />
          {formName === 'contact_investisseurs' && <div className='dropdown-container'>
            <div className='label'>{intl.formatMessage({ id: 'contact.form.field.agency.label' })}</div>
            <Dropdown
              customClass="dropdown-contact-agency"
              list={agenciesList}
              onChange={dropDownChange}
            ></Dropdown>
          </div>}

          <Textarea
            validator={validator.current}
            field={{
              id: 'message',
              filed_type: 'textarea',
              type: 'textarea',
              placeholder: intl.formatMessage({ id: 'contact.form.field.message.placeholder' }),
              name: 'message',
              label: intl.formatMessage({ id: 'contact.form.field.message.label' }),
              value: message,
              textClear: intl.formatMessage({ id: 'contact.form.field.clear_field' })
            }}
            reset={() => { setMessage('') }}
            change={(value) => { setMessage(value) }}
          />
          {/* <Checkbox
            validator={validator.current}
            field={{
              id: 'accept_checkbox',
              filed_type: 'checkbox',
              type: 'checkbox',
              name: '?????',//ReturnTranslation('form-accept', langCode),
              label: 'C\'est quoi ce champ ?????', //ReturnTranslation('form-accept', langCode) + '*',
              value: acceptCondition
            }}
            change={(id, value) => setAcceptCondition(value)}
          /> */}
          <FormattedMessage
            id="common.form.msg_body">{x => <p className="legal_test" dangerouslySetInnerHTML={{ __html: x }}></p>}
          </FormattedMessage>
          <div className="btn_container">
            <ReCaptcha
              action="/"
              size="invisible"
              badge="bottomright"
              siteKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              onVerify={(token) => handleSubmitForm(token)}
              submitted={submitted}
            />

            <Button
              id="submit"
              primary={true}
              label={intl.formatMessage({ id: 'contact.cta.submit' })}
              onClick={(e) => { e.preventDefault(); setSubmitted(true) }}
            //onClick={handleSubmitForm}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
