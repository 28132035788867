import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import {
  HorizontalYellowLineShape2,
  IconBTAlert,
  IconBTDocument,
  RHIcon,
} from '../../../Atoms/Icons/Icons';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ContactForm from '../../../components/ContactForm/ContactForm';
import PageBanner from '../../../components/PageBanner/PageBanner';
import Seo from '../../../components/Seo/Seo';
import useMedias from '../../../hooks/useMedias';
import Layout from '../../../Layout';
import AlertBlock from '../../../Molecules/AlertBlock/AlertBlock';
import GraphRH from '../../../Molecules/GraphRH/GraphRH';
import { TitleContact } from '../../../Molecules/TitleContact/TitleContact';
import { TitlePage } from '../../../Molecules/TitlePage/TitlePage';

import './presse.scss';

const classNames = require('classnames');

const ContactTemplate = ({ data, pageContext }) => {
  const { getImage } = useMedias();

  const locale = pageContext.locale;
  const formData = data.forms.edges[0].node;
  const imagesArray = data.allImages.edges;
  const blocData = formData.relationships.field_blocs;
  const [contactFormName, setContactFormName] = useState('');
  const contact_internal_id = pageContext.node_internal_id;
  const ourContact = data.ourContact.edges[0]?.node;
  const metaTags =  formData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
   metaTags.forEach((meta) => {
      if(meta.attributes.name === 'title'){
           metaTitle = meta.attributes.content;
      }
      if(meta.attributes.name === 'description'){
           metaDesc = meta.attributes.content;
      }
    });

  useEffect(() => {
    if (contact_internal_id == 162) {
      setContactFormName('contact_presse');
    } else if (contact_internal_id == 165) {
      setContactFormName('contact_rh');
    } else if (contact_internal_id == 164) {
      setContactFormName('contact_investisseurs');
    } else if (contact_internal_id == 163) {
      setContactFormName('contact');
    }
  }, []);

  const titleH4 = function (myText) {
    return myText.substring(
      myText.lastIndexOf('<h4>') + 4,
      myText.lastIndexOf('</h4>')
    );
  };

  const bodyText = function (myText) {
    return myText.split('<h4>' + titleH4(myText) + '</h4>')[1];
  };

  return (
    <Layout>
      <Seo
        title={formData?.field_metatag?.title ? formData?.field_metatag?.title : metaTitle}
        description={formData?.field_metatag?.description ? formData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'page_contact_form')}>
        <PageBanner
          visuel={getImage(
            imagesArray,
            formData.relationships?.field_image?.drupal_internal__mid
          )}
          scroll={false}
        >
          <div className="wrapper_page">
            {
              ourContact &&
              <Breadcrumb
                page_banner={true}
                data={{
                  // grandParentPage: { title: 'Acceuil', url: '/' },
                  parentPage: { title: 'Contact', url: ourContact.link?.url },
                  currentPage: { title: formData.title, url: '' },
                  locale,
                }}
              />
            }
            <TitlePage color="color_white" title={formData.title} />
          </div>
        </PageBanner>
        <section
          className={classNames('section_content', 'section_contact_form')}
        >
          <div className="wrapper_page">
            <TitleContact title={formData.field_form_title}></TitleContact>
            <div className="col">
              <div className="contact_form_component_container">
                <ContactForm formName={contactFormName} langCode={locale} />
              </div>

              <div className="contact-card">
                {formData.body?.processed && (
                  <div className="body-text">
                    <h4>
                      {titleH4(formData.body?.processed)}
                      <HorizontalYellowLineShape2 />
                    </h4>
                    <div
                      className="desc"
                      dangerouslySetInnerHTML={{
                        __html: bodyText(formData.body?.processed),
                      }}
                    ></div>
                  </div>
                )}
                {blocData.map((item, i) => (
                  <div className="alert_div" key={i}>
                    <p
                      dangerouslySetInnerHTML={{ __html: item.body?.processed }}
                      style={{ display: 'none' }}
                    ></p>
                    {item.field_link && (
                      <AlertBlock
                        key={i}
                        link={item.field_link.url}
                        label={item.body.processed}
                        icon={
                          item.field_type_bloc_contact == 'alert' ? (
                            <IconBTAlert />
                          ) : item.field_type_bloc_contact == 'rh' ? (
                            <RHIcon />
                          ) : (
                            <IconBTDocument />
                          )
                        }
                      />
                    )}

                    {item.field_chiffre_offre && (
                      <GraphRH
                        jobs={item.field_chiffre_offre}
                        text={item.field_offre_text}
                        textColor="color_black"
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ContactTemplateQuery(
    $locale: String!
    $slug: String!
    $node_internal_id: Int!
    $imagesID: [Int!]
  ) {
    forms: allNodeWebform(
      filter: {
        langcode: { eq: $locale }
        path: { alias: { eq: $slug } }
        drupal_internal__nid: { eq: $node_internal_id }
      }
    ) {
      edges {
        node {
          body {
            processed
          }
          langcode
          path {
            alias
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          drupal_internal__nid
          title
          field_form_title
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_contact {
                  body {
                    processed
                  }
                  field_link {
                    url
                    title
                  }
                  field_type_bloc_contact
                }
                ... on block_content__block_emploi {
                  field_offre_text
                  field_chiffre_offre
                }
              }
            }
            field_image {
              drupal_internal__mid
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
          langcode
        }
      }
    }
    ourContact: allMenuLinkContentMenuLinkContent(
      filter: {
        langcode: { eq: $locale }
        menu_name: { eq: "footer" }
        drupal_internal__id: { eq: 45 }
      }
    ) {
      edges {
        node {
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

ContactTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default ContactTemplate;
