import React, { useRef } from 'react';
import { CloseIcon } from '../../Atoms/Icons/Icons';

import './Textarea.scss';
let classNames = require('classnames');

const Textarea = ({ value, reset, optional, type, validator, field, change }) => {
  const inputRef = useRef();
  const params = !optional ? 'required|' : '';
  const msg_error = validator.message(field.id, field.value, params);

  const onReset = (e) => {
    e.preventDefault();
    reset();
    inputRef.current.focus();
  }

  return (
    <div className={classNames(['textarea', field.customClass])}>
      <label>{field.label}</label>
      <div className="input_wrap">
        <textarea
          ref={inputRef}
          className={classNames({ 'value_exist': field.value.length > 0 })}
          name={field.name} value={field.value}
          aria-required={optional ? "false" : "true"}
          placeholder={field.placeholder}
          onChange={(e) => change(e.currentTarget.value)}
          {...msg_error && { 'aria-invalid': "true" }}
          {...msg_error && { 'aria-describedby': 'field_' + field.name }}
        />
        {
          msg_error && (
            <div className="error" id={`field_${field.name}`}>{msg_error}</div>
          )
        }
        {
          field.value.length > 0 && (
            <button
              className="clear"
              onClick={onReset}
              tabIndex="0"
              area-label={field.textClear + ' ' + field.label.replace('*', '')}
            >
              <CloseIcon />
            </button>
          )
        }
      </div>
    </div>
  );
}

Textarea.defaultProps = {
  label: 'Sujet de votre email',
  name: 'email',
  value: undefined,
  placeholder: 'Le sujet de votre eMail',
  type: 'alpha', /* https://www.npmjs.com/package/simple-react-validator#rules */
  optional: false
};

export default Textarea;
